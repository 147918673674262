import React from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import SEO from "../../components/Layout/SEO";
import SuccessStories from "../../sections/camelo/SuccessStories";
import timeTracking from "../../assets/image/truworth-care/camelo-time-tracking-feature.png";
import jobsiteNotes from "../../assets/image/truworth-care/camelo-job-site-notes.png";

import bgSection from "../../assets/image/customers/truworth_care_story_feature_image.png";

const MiddleCTA = () => {
  return (
    <Row className="bg-default-1 border-top">
      <Container>
        <Col xs="12" className="text-center pb-24">
          <h2 className="gr-text-5 pt-24 pb-8">
            <span
              className="highlighted1"
              style={{ backgroundPosition: "0 20px" }}
            >
              Try Camelo now and see the difference yourself.
            </span>
          </h2>
          <div>
            <Link
              onClick={() => {
                if (typeof window.gtag_report_conversion === "function") {
                  window.gtag_report_conversion(process.env.SIGNUP_URL);
                }
              }}
              to={process.env.SIGNUP_URL}
              target="__blank"
              className="btn btn-primary with-icon gr-hover-y"
            >
              Start scheduling free
              <i className="icon icon-tail-right font-weight-bold"></i>
            </Link>
            <p className="gr-text-12 pt-8">
              59 companies signed up in the last week alone!
            </p>
          </div>
        </Col>
      </Container>
    </Row>
  );
};

const TruworthCareStory = () => {
  return (
    <>
      <PageWrapper>
        <SEO title="How Truworth Care uses Camelo to save time and gain peace in employee scheduling"></SEO>
        <div className="inner-banner bg-default-6 pt-24 pt-lg-30">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9">
                <div className="px-md-12 mb-13 text-center">
                  <h1 className="title gr-text-4 mb-8 mb-lg-12">
                    How Truworth Care uses Camelo to save time and gain peace in
                    employee scheduling
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className="video-section py-15 py-sm-20 py-lg-35 dark-mode-texts "
          css={`
            background-image: url(${bgSection});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          `}
        />

        <div className="pb-2 pb-lg-9 pt-10 pt-lg-15 bg-default-6 customer-story">
          <Container>
            <Row className="justify-content-center">
              <Col xl="10" lg="10">
                <div className="px-md-12 text-black mb-13 mb-lg-22">
                  <p className="gr-text-8 text-black">
                    <Link
                      to="https://l.facebook.com/l.php?u=http%3A%2F%2Ftruworthcare.com.au%2F%3Ffbclid%3DIwZXh0bgNhZW0CMTAAAR1nYxaIz-r9YPw9MsUmvIfnhqaC77e3KgsbQwM8pZ6CTU09uyy-x9QHXs8_aem_9olhOCLQ9IFSA6mKeHMFIA&h=AT2oFkCu8uOQU-2ih1QjlgDU8oXmcPUdHVT3rQPBvZhq7SWG3742o4N-sIAyoJYI9VKQiqmc4WfXIJNqXL6wita5SoWwMxl0f2e9cflhuOcsYTMyszr9hjb32g46Xs1mWIIkRg"
                      target="__blank"
                    >
                      {`Truworth Care `}
                    </Link>
                    is a registered NDIS service provider based in Cairns, Far
                    North Queensland. They provide High-Intensity Daily Personal
                    Activities including complex bowel care, enteral feeding and
                    management, severe dysphagia management, tracheostomy
                    management, urinary catheter management, subcutaneous
                    injection, complex wound care and implementation of
                    behaviour support plans.
                  </p>
                  <p className="gr-text-8 text-black">
                    Scheduling used to be a slow and time-consuming process for
                    Truworth Care when arranging shifts with paper and pen
                    wasn’t effective. After self-educating on technology and
                    doing some searching, Nokuthula Sibanda—the owner of
                    Truworth Care— found Camelo a good match for her scheduling
                    demands.
                  </p>
                  <h4 className="pt-8 pb-4">
                    Two professionals came together to make a difference
                  </h4>
                  <p className="gr-text-8 text-black">
                    Truworth Care began their journey in 2022 when Nokuthula
                    Sibanda and her husband thought of combining their skills
                    and becoming disability service providers under the NDIS in
                    Australia. “We thought we had the experience and decided to
                    use it,” she said.
                  </p>
                  <p className="gr-text-8 text-black">
                    Nokuthula is a Registered Nurse with vast experience in
                    Rehabilitation and Neurology, Diabetes Educator and Wound
                    Ostomy and Continence Nurse (WOCN). Meanwhile, her husband
                    is a disability support worker.
                  </p>
                  <p className="gr-text-8 text-black">
                    With their extensive experience in disability services, the
                    two experts decided to open Truworth Care. Their mission is
                    to advance the quality of life for people with disabilities
                    and improve the health and well-being of all by focusing on
                    individuals’ unique, abilities, strengths, interests, and
                    preferences.
                  </p>
                  <h4 className="pt-8 pb-4">
                    Facing challenges when scheduling in ordinary ways
                  </h4>
                  <p className="gr-text-8 text-black">
                    Managing a large number of staff wasn’t an easy task for
                    Nokuthula. She told us the scheduling process was slow and
                    took a lot of time as they used pen and paper. Some mistakes
                    kept happening again and again such as forgetting to update
                    someone, scheduling the wrong people for shifts, or
                    miscalculating work hours, etc.
                  </p>
                  <p className="gr-text-8 text-black">
                    Nokuthula also found it difficult to communicate with her
                    employees. They used Google Keep to exchange progress notes
                    as a way to update their tasks’ statuses. But it was a
                    temporary solution because the amount of information to
                    handle would be overwhelming once their team expanded.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “Hiring someone to handle every aspect is extremely
                      expensive and it’s hard to find a tool which can help you
                      to do most of the business needs.“
                    </p>
                  </blockquote>
                  <p className="gr-text-8 text-black">
                    “We are educating ourselves on technology in order to save
                    time and money,” she shared.
                  </p>
                  <h4 className="pt-8 pb-4">
                    Making the switch: a smooth transition to Camelo!
                  </h4>
                  <p className="gr-text-8 text-black">
                    Nokuthula’s focus on implementing technology into work had
                    finally led her to a new turn. One day she found out about
                    Camelo and decided to give it a try. After
                    testing Camelo's interface and exploring its features for
                    just a few days, the organisation owner was convinced.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “The scheduling, time tracking, and communication through
                      boards with staff were easy.”
                    </p>
                  </blockquote>
                  <h4 className="pt-8 pb-4">
                    An all-in-one tool for business needs
                  </h4>
                  <p className="gr-text-8 text-black">
                    With Camelo, now Nokuthula rarely makes any mistakes while
                    scheduling. Her staff are scheduled for the right shifts and
                    their work hours are no longer miscalculated.
                  </p>
                  <p className="gr-text-8 text-black">
                    Managing her staff’s clock-ins and clock-outs is so much
                    easier now with the
                    <Link
                      to="https://camelohq.com/features/time-and-attendance"
                      target="__blank"
                    >
                      {` time-tracking feature. `}
                    </Link>
                    Communication of changes with employees is also convenient
                    since they can send messages and announcements through
                    <Link
                      to="https://camelohq.com/features/workplace-communication"
                      target="__blank"
                    >
                      {` chats and message boards. `}
                    </Link>
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      ”Camelo is a tool that is closer to providing all my
                      business needs in one app.”
                    </p>
                  </blockquote>
                  <img
                    className="w-100"
                    src={timeTracking}
                    alt="Camelo’s time tracking feature"
                  />
                  <p className="text-center font-italic">
                    Clock in and clock out with Camelo’s time-tracking feature
                  </p>
                  <h4 className="pt-8 pb-4">
                    The game changer for Truworth Care
                  </h4>
                  <p className="gr-text-8 text-black">
                    One of Camelo’s features which Nokuthula found useful for
                    her team is the Note section. They used to have their
                    progress notes kept on Google Keep to update their work
                    status. But organising the notes is an exhausting task and
                    it took so much time whenever they needed to find any piece
                    of information.
                  </p>
                  <p className="gr-text-8 text-black">
                    Now with the Note section in Camelo’s scheduler, Truworth
                    Care team can easily add progress notes to communicate and
                    keep up to date with the participants’ status per shift.
                    They no longer have to drown in hundreds of files to search
                    for the required data.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      ”The note section was a game-changer for us. We eliminated
                      using another app!”
                    </p>
                  </blockquote>
                  <img
                    className="w-100"
                    src={jobsiteNotes}
                    alt="Camelo’s job site notes"
                  />
                  <p className="text-center font-italic">
                    Add notes to shifts in Camelo’s scheduler
                  </p>
                  <h4 className="pt-8 pb-4">
                    Keep growing, saving time, and gaining peace with Camelo!
                  </h4>
                  <p className="gr-text-8 text-black">
                    About her experience using Camelo, Nokuthula is eager to
                    share her satisfaction. ”Save time and gain peace!” she
                    said.
                  </p>
                  <p className="gr-text-8 text-black">
                    When asked if she would recommend Camelo to others, she
                    doesn’t hide how ready she is.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      ”Yes. I would recommend this tool to others, especially
                      with all the improvements happening!”
                    </p>
                  </blockquote>
                  <p className="gr-text-8 text-black">
                    As a disability service provider operating in Australia's
                    structured NDIS environment, Truworth Care values technology
                    that can adapt to their specific requirements. For Truworth
                    Care, Camelo has made scheduling easier, allowing them to
                    focus on what matters most—providing exceptional disability
                    support services to those who need them most.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <MiddleCTA />
        <SuccessStories />
      </PageWrapper>
    </>
  );
};
export default TruworthCareStory;
